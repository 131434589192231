import React from "react"
import { CircularProgress } from "@material-ui/core"

const Loader = ({ height = 100 }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: height,
      }}
    >
      <CircularProgress/>
    </div>
  )
}

export default Loader
